.strikethrough-diagonal {
  position: relative;
  color: white;
  font-weight: bold;

  &:before {
    position: absolute;
    content: "";
    left: -10%;
    top: 45%;
    right: -10%;
    border-top: 8px solid;
    border-color: #004aad;
    -webkit-transform: skewY(-10deg);
    -moz-transform: skewY(-10deg);
    transform: skewY(-10deg);
  }
}

.snap-active {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.flashing {
  position: relative;
}

.flashlight {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  pointer-events: none;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.6);
  mix-blend-mode: difference;
}

.flashlight-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at center, transparent 0%, rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 1) 80%);
  mix-blend-mode: screen;
}
.map-container {
  height: 100%;
  width: 100%;
}

